import { LinkButton, PrimaryButton } from '@margobank/components/Button';
import { useIntl } from '@margobank/components/intl';
import { StickerIcon } from '@margobank/components/Sticker';

import { useHelpBeacon } from 'components/help-beacon';

import SecondFactorValidationLayout from './SecondFactorValidationLayout';

type Props = {
  onCancel: () => void;
};

const SecondFactorValidationExpired = ({ onCancel }: Props) => {
  const { openMessageBox } = useHelpBeacon();
  const { t } = useIntl();

  return (
    <SecondFactorValidationLayout
      body={t('auth.secondFactorValidation.expiredBody')}
      footerElement={
        <>
          <PrimaryButton onClick={onCancel}>{t('common.actions.close')}</PrimaryButton>
          <LinkButton onClick={() => openMessageBox()}>
            {t('auth.secondFactorValidation.contactButton')}
          </LinkButton>
        </>
      }
      stickerElement={<StickerIcon icon="large/cross" size="x-large" variant="red" />}
      title={t('auth.secondFactorValidation.expiredTitle')}
    />
  );
};

export default SecondFactorValidationExpired;
