import { useState } from 'react';

import { PrimaryButton } from '@margobank/components/Button';
import type { AuthenticatedCustomerDTO } from '@margobank/components/domain/customer/types';
import { PermissionsList, USER_ACCESS_ROLE } from '@margobank/components/domain/user';
import { ActionBar } from '@margobank/components/form';
import { useIntl } from '@margobank/components/intl';
import { Column, HorizontalRule } from '@margobank/components/layout';
import Modal from '@margobank/components/Modal';
import { Body, Heading, Subheading } from '@margobank/components/text';

import { useCreateAccess, useLoadInvitation } from 'app/legacy-customer/queries';

import { ColumnWithPadding } from './_styled';

type Props = {
  customer: AuthenticatedCustomerDTO;
};

const WelcomeModal = ({ customer: { id: customerId, lastAccess, user } }: Props) => {
  const { t } = useIntl();

  const [createAccess] = useCreateAccess();
  const [invitation] = useLoadInvitation(
    { customerId },
    // Owners don't have invitations
    { enabled: user.role !== USER_ACCESS_ROLE.OWNER },
  );

  // Show the modal while the customer "last access" is NULL.
  const isFirstAccess = lastAccess === null;
  const [isOpen, setIsOpen] = useState(isFirstAccess);

  if (!invitation || !isFirstAccess) {
    return null;
  }

  const handleRequestClose = () => {
    setIsOpen(false);
    // While the user access is NULL, we display the modal.
    // This dispatch will define the first user access
    // and prevent the modal to display again (for this customer).
    createAccess({ customerId });
  };

  const { companyName, permissions, role } = invitation;

  return (
    <Modal
      isOpen={isOpen}
      noPadding
      onRequestClose={handleRequestClose}
      shouldCloseOnOverlayClick={false}
    >
      <ColumnWithPadding spacing="s4">
        <Column spacing="s1_5" textAlign="center">
          <Heading>{t('customer.welcome.title')}</Heading>
          <Body>{t(`customer.welcome.subtitle.${role}`, { companyName })}</Body>
        </Column>
        <Column spacing="s3">
          <HorizontalRule />
          <Subheading>
            {t(`customer.welcome.permissionsTitle.${role}`, {
              em: (chunk) => (
                <Body bold highlighted inline>
                  {chunk}
                </Body>
              ),
            })}
          </Subheading>
          <PermissionsList permissions={permissions} />
        </Column>
        <ActionBar>
          <PrimaryButton onClick={handleRequestClose}>{t('customer.welcome.submit')}</PrimaryButton>
        </ActionBar>
      </ColumnWithPadding>
    </Modal>
  );
};

export default WelcomeModal;
