import type { ComponentProps } from 'react';

import { ErrorAlert } from '@margobank/components/alert';
import { LinkButton, PrimaryButton } from '@margobank/components/Button';
import { useIntl } from '@margobank/components/intl';
import { Column } from '@margobank/components/layout';
import { Body, Subheading } from '@margobank/components/text';

import unlockDevices from 'assets/images/unlock-devices.svg';
import { useHelpBeacon } from 'components/help-beacon';

import PairingCard from '../../../_parts/PairingCard';
import PairingLayout from '../../../_parts/PairingLayout';

type Props = Pick<ComponentProps<typeof PairingLayout>, 'closeButton'> & {
  deviceName: string;
  error?: string;
  onConfirm: () => void;
};

const Start = ({ deviceName, error, onConfirm, ...rest }: Props) => {
  const { showHelpArticle } = useHelpBeacon();
  const { t } = useIntl();

  return (
    <PairingLayout {...rest} title={t('auth.updatePairing.title')}>
      <PairingCard>
        <img alt={t('auth.updatePairing.title')} height="140" src={unlockDevices} width="240" />
        {error && <ErrorAlert message={error} />}
        <Column spacing="s1">
          <Subheading>{t('auth.updatePairing.start.intro')}</Subheading>
          <Body subdued>{t('auth.updatePairing.start.body', { deviceName })}</Body>
        </Column>
        <PrimaryButton onClick={onConfirm}>{t('auth.updatePairing.start.confirm')}</PrimaryButton>
        <LinkButton onClick={() => showHelpArticle('PAIRING_INSTALLATION')}>
          {t('auth.updatePairing.start.checkDevice')}
        </LinkButton>
      </PairingCard>
    </PairingLayout>
  );
};

export default Start;
