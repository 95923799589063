import { useState } from 'react';
import type { ReactNode } from 'react';
import { useQueryClient } from 'react-query';

import { ErrorAlert } from '@margobank/components/alert';
import { PrimaryButton } from '@margobank/components/Button';
import useDownload, { useLoadFile } from '@margobank/components/download';
import FileCard from '@margobank/components/FileCard';
import { CheckboxField, createForm, handleFormError } from '@margobank/components/form';
import { explicitAgreement } from '@margobank/components/form/validators';
import { useIntl } from '@margobank/components/intl';
import { Column } from '@margobank/components/layout';
import ViewerModal from '@margobank/components/Modal/viewer';
import { Heading, Subheading } from '@margobank/components/text';

import SideImageLayout from 'app/auth/_parts/SideImageLayout';
import { loadUser } from 'app/auth/actions';
import { useConfirmTerms } from 'app/auth/mutations';
import type { TermsAcceptanceDTO, UserDTO } from 'app/auth/types';
import { useLoadCustomers } from 'app/legacy-customer/queries';
import { useDispatch } from 'common/store';

const optInIsChecked = explicitAgreement();

const Form = createForm('auth.uploadIdentityProof');

type Document = {
  fileName: string;
  mimeType: string;
  name: string;
  url: string;
};

type Props = {
  onConfirm?: () => void;
  renderFooter?: () => ReactNode;
  user: UserDTO;
};

const ConfirmConvention = ({ onConfirm, renderFooter, user }: Props) => {
  const dispatch = useDispatch();
  const download = useDownload();
  const { t } = useIntl();
  const loadFile = useLoadFile();
  const queryClient = useQueryClient();
  const [viewerFile, setViewerFile] = useState<Document | null>(null);

  const [confirmTerms] = useConfirmTerms();

  const conventionDocument: Document = {
    fileName: t('auth.confirmConvention.convention.fileName'),
    mimeType: 'application/pdf',
    name: t('auth.confirmConvention.convention.title'),
    url: t('link.generalConditions'),
  };

  const handleAfterSuccess = () => {
    // If customer is null, redirecting the user to / will redirect him back to /onboarding
    queryClient.invalidateQueries(useLoadCustomers.getQueryKey());
    dispatch(loadUser()).then(() => {
      if (onConfirm) {
        return onConfirm();
      }
    });
  };

  const handleDocumentDownload = ({ fileName, url }: Document) => {
    download(url, fileName);
  };

  const handleSubmit = (params: TermsAcceptanceDTO) => {
    return confirmTerms({ userId: user.id, params }).then(
      () => handleAfterSuccess(),
      (error) => handleFormError(error),
    );
  };

  const loadViewerFile = ({ fileName, url }: Document) => {
    return loadFile(url, fileName);
  };

  return (
    <SideImageLayout renderFooter={renderFooter} variant="laptop" withUserDropdown>
      <Column alignItems="center" spacing="s3">
        <Column spacing="s2">
          <Heading>{t('auth.confirmConvention.title')}</Heading>
          <Subheading light subdued>
            {t('auth.confirmConvention.notice')}
          </Subheading>
        </Column>
        <Form onSubmit={handleSubmit}>
          {(form) => (
            <Column spacing="s3" textAlign="left">
              {form.error && <ErrorAlert message={form.error} />}
              <FileCard
                mimeType={conventionDocument.mimeType}
                onDownloadClick={() => handleDocumentDownload(conventionDocument)}
                onPreviewClick={() => setViewerFile(conventionDocument)}
                title={conventionDocument.name}
                withSticker
              />
              <CheckboxField
                label={t('auth.confirmConvention.certify')}
                name="accepted"
                validate={optInIsChecked}
              />
              <PrimaryButton isLoading={form.submitting} type="submit">
                {t('auth.confirmConvention.submit')}
              </PrimaryButton>
            </Column>
          )}
        </Form>
      </Column>
      {viewerFile && (
        <ViewerModal
          fileMetadata={viewerFile}
          loadFile={() => loadViewerFile(viewerFile)}
          onDownloadClicked={() => handleDocumentDownload(viewerFile)}
          onRequestClose={() => setViewerFile(null)}
        />
      )}
    </SideImageLayout>
  );
};

export default ConfirmConvention;
