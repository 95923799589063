import axios from 'axios';
import queryString from 'query-string';
import { v4 as uuidv4 } from 'uuid';

import type { AuthStore } from 'app/auth/hooks';
import { selectSessionToken } from 'app/auth/selectors';
import { AUTOMATION_API_ROOT, BACKEND_API_ROOT, ENVIRONMENT, FAKE_API_ROOT } from 'common/env';
import type { State } from 'common/store';

import { name as packageName } from '../../package.json';
import { loadGeoTesting } from './geoTesting';

export { BACKEND_API_ROOT };

export const ACCOUNTING_SERVICE_PREFIX = '/accounting';
export const AUTOMATION_SERVICE_PREFIX = '/automation';
export const CUSTOMERS_SERVICE_PREFIX = '/customers';
export const EBICS_SERVICE_PREFIX = '/ebics';
export const GRANTINGS_SERVICE_PREFIX = '/grantings';
export const NOTIFICATIONS_SERVICE_PREFIX = '/notifications';
export const RECOVERY_SERVICE_PREFIX = '/recovery';
export const REMUNERATIONS_SERVICE_PREFIX = '/remunerations';
export const SUBSCRIPTIONS_SERVICE_PREFIX = '/subscriptions';
export const SWIFT_STATEMENT_SERVICE_PREFIX = '/swift_statement';
export const USERS_SERVICE_PREFIX = '/users';

const TEST_COUNTRY_CODE = 'Test-Country-Code';
export const X_APPROVAL_REQUEST_ID = 'X-Approval-Request-Id';
export const X_SMS_OTP = 'X-SMS-OTP';
export const X_AUTH_TOKEN = 'X-Auth-Token';
const X_CORRELATION_ID = 'X-Correlation-Id';
const X_LOCALE = 'X-Locale';
const X_USER_AGENT = 'X-User-Agent';

// API Versioning
export const V2_ACCEPT_HEADER_VALUE = 'application/vnd.memo-bank.v2+json';
export const V3_ACCEPT_HEADER_VALUE = 'application/vnd.memo-bank.v3+json';

const setHeaders = (config) => {
  config.headers.common[X_CORRELATION_ID] = uuidv4();

  if (ENVIRONMENT !== 'production') {
    const { countryCode } = loadGeoTesting();
    if (!!countryCode) {
      config.headers.common[TEST_COUNTRY_CODE] = countryCode;
    }
  }

  return config;
};

const setHeadersFromState = (config, getState: () => State, getAuthState?: () => AuthStore) => {
  const state = getState();
  const sessionToken = selectSessionToken(state);

  if (getAuthState) {
    const { fingerprintId, getLocale } = getAuthState();
    config.headers.common[X_LOCALE] = getLocale();

    if (fingerprintId) {
      config.headers.common[X_USER_AGENT] = `${packageName}; id=${fingerprintId}`;
    } else {
      config.headers.common[X_USER_AGENT] = packageName;
    }
  }

  if (sessionToken) {
    config.headers.common[X_AUTH_TOKEN] = sessionToken;
  }
  return config;
};

const paramsSerializer = (params) => {
  // Will use the default "none" formatting for the array params
  // cf. https://github.com/sindresorhus/query-string#arrayformat
  return queryString.stringify(params);
};

const http = axios.create({ baseURL: BACKEND_API_ROOT, paramsSerializer });
const httpAutomation = axios.create({ baseURL: AUTOMATION_API_ROOT, paramsSerializer });
const httpFake = FAKE_API_ROOT ? axios.create({ baseURL: FAKE_API_ROOT, paramsSerializer }) : null;

http.interceptors.request.use(setHeaders);
httpAutomation.interceptors.request.use(setHeaders);
httpFake?.interceptors.request.use(setHeaders);

export const setupHttpHeadersFromState = (getState: () => State, getAuthState: () => AuthStore) => {
  http.interceptors.request.use((config) => setHeadersFromState(config, getState, getAuthState));
  httpAutomation.interceptors.request.use((config) =>
    setHeadersFromState(config, getState, getAuthState),
  );
  httpFake?.interceptors.request.use((config) => setHeadersFromState(config, getState));
};

export { httpAutomation, httpFake };

export default http;
