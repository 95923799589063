import { useEffect } from 'react';
import type { ComponentProps } from 'react';
import { useMount } from 'react-use';

import { getErrorStatus, HTTP_FORBIDDEN } from '@margobank/components/common/error';
import { useIntl } from '@margobank/components/intl';
import { Column, Row } from '@margobank/components/layout';
import Loader, { useLoadingState } from '@margobank/components/Loader';
import { Body, Subheading } from '@margobank/components/text';

import { clearDeviceReplacementVerificationToken, loadPairingQRcode } from 'app/auth/actions';
import { useLoadUnpairedDeviceInformation } from 'app/auth/queries';
import { selectDeviceReplacementVerificationToken, selectPairingQRCode } from 'app/auth/selectors';
import { useDispatch, useSelector } from 'common/store';

import PairingCard from '../../../_parts/PairingCard';
import PairingLayout from '../../../_parts/PairingLayout';
import { QRCodeImg } from '../../../_styled';

const REFETCH_INTERVAL_DELAY_MS = 1_000;

type Props = Pick<ComponentProps<typeof PairingLayout>, 'closeButton' | 'onPrevious'> & {
  onConfirm: (deviceId: string) => void;
  onTokenExpired: () => void;
  userId: string;
};

const FlashCode = ({ onConfirm, onTokenExpired, userId, ...rest }: Props) => {
  const dispatch = useDispatch();
  const { t } = useIntl();
  const { LoadingState, watch } = useLoadingState();
  const pairingQRCode = useSelector(selectPairingQRCode);
  const token = useSelector(selectDeviceReplacementVerificationToken);

  const [newDeviceToPairInformation] = useLoadUnpairedDeviceInformation(
    { userId },
    {
      refetchInterval: (device) =>
        device && device.needsConfirmation ? false : REFETCH_INTERVAL_DELAY_MS,
    },
  );

  useMount(() => {
    if (!token) {
      return onTokenExpired();
    }
    // Fetch the QRCode
    watch(dispatch(loadPairingQRcode(userId, token))).catch((error) => {
      const statusCode = getErrorStatus(error);
      if (statusCode === HTTP_FORBIDDEN) {
        dispatch(clearDeviceReplacementVerificationToken());
        onTokenExpired();
      }
    });
  });

  useEffect(() => {
    // Device paired, go to the next step
    if (pairingQRCode && newDeviceToPairInformation?.needsConfirmation) {
      onConfirm(newDeviceToPairInformation.id);
    }
  }, [newDeviceToPairInformation, onConfirm, pairingQRCode]);

  return (
    <PairingLayout
      {...rest}
      subtitle={t('auth.pairing.flashCode.subtitle')}
      title={t('auth.updatePairing.title')}
    >
      {!pairingQRCode ? (
        <Column alignItems="center">
          <LoadingState />
        </Column>
      ) : (
        <PairingCard spacing="s4">
          <QRCodeImg
            alt={t('auth.pairing.flashCode.qrcode')}
            height="148"
            src={pairingQRCode.qrCodeUrl}
            width="148"
          />
          <Subheading>{t('auth.updatePairing.flashCode.body')}</Subheading>
          {newDeviceToPairInformation && (
            <Row alignItems="center" spacing="s1">
              <Loader />
              <Body subdued>
                {t('auth.pairing.flashCode.loading', {
                  deviceName: newDeviceToPairInformation.name,
                })}
              </Body>
            </Row>
          )}
        </PairingCard>
      )}
    </PairingLayout>
  );
};

export default FlashCode;
