import type { ComponentProps } from 'react';

import { PrimaryButton } from '@margobank/components/Button';
import type { UserDTO } from '@margobank/components/domain/user';
import { useIntl } from '@margobank/components/intl';
import { Column } from '@margobank/components/layout';
import { Body, Subheading } from '@margobank/components/text';

import { useLoadPairedDeviceInformation } from 'app/auth/queries';
import { selectUser } from 'app/auth/selectors';
import pairedPhone from 'assets/images/paired-phone.svg';
import { useSelector } from 'common/store';

import PairingCard from '../../../_parts/PairingCard';
import PairingLayout from '../../../_parts/PairingLayout';

type Props = Pick<ComponentProps<typeof PairingLayout>, 'closeButton'> & {
  onConfirm: () => void;
};

const Validation = ({ closeButton, onConfirm }: Props) => {
  const { id: userId } = useSelector(selectUser) as UserDTO;
  const { t } = useIntl();

  const [pairedDeviceInformation, { LoadingState }] = useLoadPairedDeviceInformation({ userId });

  if (!pairedDeviceInformation) {
    return <LoadingState />;
  }

  const { name: deviceName } = pairedDeviceInformation;

  return (
    <PairingLayout
      closeButton={closeButton && { ...closeButton, label: t('common.actions.close') }}
      subtitle={t('auth.pairing.validation.subtitle')}
      title={t('auth.updatePairing.title')}
    >
      <PairingCard>
        <img
          alt={t('auth.pairing.validation.intro', { deviceName })}
          height="140"
          src={pairedPhone}
          width="72"
        />
        <Column spacing="s1" textAlign="center">
          <Subheading>{t('auth.pairing.validation.intro', { deviceName })}</Subheading>
          <Body subdued>{t('auth.pairing.validation.body')}</Body>
        </Column>
        <PrimaryButton onClick={onConfirm}>{t('auth.pairing.validation.success')}</PrimaryButton>
      </PairingCard>
    </PairingLayout>
  );
};

export default Validation;
