import type { ComponentProps } from 'react';

import { useIntl } from '@margobank/components/intl';
import { FormCompletionMessage } from '@margobank/components/page';

import { useHelpBeacon } from 'components/help-beacon';

import PairingCard from '../../../_parts/PairingCard';
import PairingLayout from '../../../_parts/PairingLayout';

type Props = Pick<ComponentProps<typeof PairingLayout>, 'closeButton' | 'onPrevious'> & {
  onRetry: () => void;
};

const Rejected = ({ onRetry, ...rest }: Props) => {
  const { openMessageBox } = useHelpBeacon();
  const { t } = useIntl();

  return (
    <PairingLayout {...rest} title={t('auth.updatePairing.title')}>
      <PairingCard>
        <FormCompletionMessage
          body={t('auth.updatePairing.rejected.body')}
          mainButton={{
            title: t('auth.updatePairing.rejected.retry'),
            onClick: onRetry,
          }}
          optionalButton={{
            title: t('auth.secondFactorValidation.contactButton'),
            onClick: () => openMessageBox(),
          }}
          stickerVariant="error"
          title={t('auth.updatePairing.rejected.title')}
        />
      </PairingCard>
    </PairingLayout>
  );
};

export default Rejected;
