import { PrimaryButton } from '@margobank/components/Button';
import { useIntl } from '@margobank/components/intl';
import { Column } from '@margobank/components/layout';
import { Body, Subheading } from '@margobank/components/text';
import { Sticker } from '@memobank/ui';

type Props = {
  body: string;
  buttonText?: string;
  onConfirm: () => void;
  title: string;
};

const Success = ({ body, buttonText, onConfirm, title }: Props) => {
  const { t } = useIntl();

  return (
    <Column alignItems="center" maxWidth="medium" padding="s3" spacing="s3" textAlign="center">
      <Sticker sticker="x-large/check" />
      <Column spacing="s1">
        <Subheading>{title}</Subheading>
        <Body subdued>{body}</Body>
      </Column>
      <PrimaryButton onClick={onConfirm}>{buttonText || t('common.actions.ok')}</PrimaryButton>
    </Column>
  );
};

export default Success;
