import { useEffect } from 'react';
import { Redirect } from 'react-router-dom';

import { useFeature } from '@margobank/components/feature-toggles';
import Loader from '@margobank/components/Loader';

import { logout } from 'app/auth/actions';
import { AUTH_ERROR } from 'app/auth/constants';
import { useLoadGrantingFiles } from 'app/grantings/queries';
import { useLoadCustomers } from 'app/legacy-customer/queries';
import { AVAILABLE_FEATURE } from 'common/constants';
import { useDispatch } from 'common/store';

import type { RouteProps } from '.';
import { PrivateRoute } from '.';
import { getLastActiveCustomerHomePath, getLastActiveGrantingHomePath } from './helpers';

type Props = RouteProps;

const HomeRedirect = () => {
  const isSandboxEnabled = useFeature(AVAILABLE_FEATURE.SANDBOX);

  const dispatch = useDispatch();
  const [customers] = useLoadCustomers();
  const [grantingFiles = isSandboxEnabled ? [] : undefined] = useLoadGrantingFiles();

  const isLoading = typeof customers === 'undefined' || typeof grantingFiles === 'undefined';

  useEffect(() => {
    // If a user has no granting files and no customers, he's logged out with a message.
    // eg. When a user is removed from his last customer.
    if (!isLoading && !grantingFiles?.length && !customers?.length) {
      dispatch(logout(AUTH_ERROR.ORPHAN_USER));
    }
  }, [customers, dispatch, grantingFiles, isLoading]);

  // Wait for the customer and granting files
  if (isLoading) {
    return <Loader />;
  }

  // No customer but an onboarding to finish
  if (grantingFiles?.length && !customers?.length) {
    return <Redirect to={getLastActiveGrantingHomePath(grantingFiles)} />;
  }

  if (customers?.length) {
    return <Redirect to={getLastActiveCustomerHomePath(customers)} />;
  }

  return <Loader />;
};

const PrivateHomeRedirect = (props: Props) => {
  const { component, render, ...rest } = props;
  return <PrivateRoute {...rest} component={HomeRedirect} />;
};

export default PrivateHomeRedirect;
