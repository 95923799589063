import { LinkButton, PrimaryButton } from '@margobank/components/Button';
import { useIntl } from '@margobank/components/intl';
import { Column } from '@margobank/components/layout';
import { StickerIcon } from '@margobank/components/Sticker';
import { Body, Subheading } from '@margobank/components/text';

import { useHelpBeacon } from 'components/help-beacon';

type Props = {
  body?: string;
  buttonText?: string;
  onRetry: () => void;
  title?: string;
};

const Rejected = ({ body, buttonText, onRetry, title }: Props) => {
  const { openMessageBox } = useHelpBeacon();
  const { t } = useIntl();

  return (
    <Column alignItems="center" maxWidth="medium" padding="s3" spacing="s3" textAlign="center">
      <StickerIcon icon="large/cross" size="x-large" variant="red" />
      <Column spacing="s1">
        <Subheading>{title || t('auth.secondFactorValidation.rejectedTitle')}</Subheading>
        <Body subdued>{body || t('auth.secondFactorValidation.rejectedBody')}</Body>
      </Column>
      <PrimaryButton onClick={onRetry}>{buttonText || t('common.actions.retry')}</PrimaryButton>
      <LinkButton onClick={() => openMessageBox()}>
        {t('auth.secondFactorValidation.contactButton')}
      </LinkButton>
    </Column>
  );
};

export default Rejected;
